<template>
  <div>
    <v-subheader v-if="name && navItems.length > 0" class="clickable" @click="active = !active">
      {{name}}
    </v-subheader>

    <!-- nav links -->
    <v-list-item
      v-for="item in navItems"
      :key="item.text"
      :to="item.route"
      link
      :style="'margin-left: ' + item.indentPx + 'px;'"
      v-show="active"
    >
      <v-list-item-action>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
      
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'NavSection',

  props: {
    name: { type: String, required: true },
    navItems: { type: Array, required: true },
    indentPx: { type: Number, required: false, default: 0 },
  },

  data () {
    return {
      active: true
    }
  }
}
</script>

<style scoped>

</style>